import React from "react"
import { Animation } from "gatsby-theme-portfolio-minimal/src/components/Animation"
import { Section } from "gatsby-theme-portfolio-minimal/src/components/Section"
import { PageSection } from "gatsby-theme-portfolio-minimal/src/types"
import * as classes from "./style.module.css"

export function ContactSection(props: PageSection): React.ReactElement {
  return (
    <Animation type="fadeUp">
      <Section
        anchor={props.sectionId}
        heading={props.heading}
        additionalClasses={[classes.Contact]}
      >
        <p>
          <strong>Email:</strong> <a href="mailto:ac@anselm.dk">ac@anselm.dk</a>{" "}
          <br />
          <strong>Phone:</strong> +45/60151100 <br />
          <strong>LinkedIn:</strong>{" "}
          <a
            href="https://dk.linkedin.com/in/anselmchristophersen"
            target="_blank"
            rel="noopener noreferrer"
          >
            anselmchristophersen
          </a>{" "}
          <br />
          <strong>Instagram:</strong>{" "}
          <a
            href="https://www.instagram.com/anselmchristophersen"
            target="_blank"
            rel="noopener noreferrer"
          >
            anselmchristophersen
          </a>
          <br />
          <strong>Twitter:</strong>{" "}
          <a
            href="https://twitter.com/anselmdk"
            target="_blank"
            rel="noopener noreferrer"
          >
            anselmdk
          </a>{" "}
          <br />
          <strong>Github:</strong>{" "}
          <a
            href="https://github.com/anselmdk"
            target="_blank"
            rel="noopener noreferrer"
          >
            anselmdk
          </a>
        </p>
      </Section>
    </Animation>
  )
}
